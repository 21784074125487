import React from 'react'
import { trailerHolder } from './Trailer.module.scss'
import { AnimatePresence, motion } from 'framer-motion'

const variants = {
  visible: {
    opacity:1,
    scale:1
  },
  hidden: {
    opacity:0,
    scale:1.3
  }
}


export default function Trailer({showTrailer, setShowTrailer, trailer}) {
  //TODO
  let videoIdArray = trailer.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
  let videoId = videoIdArray[1]


  return (
    <AnimatePresence>
      { showTrailer && <motion.div 
          key="trailerHolder"
          className={trailerHolder}
          variants={variants}
          animate='visible'
          exit='hidden'
          initial="hidden"
          onClick={()=>setShowTrailer(false)}>
        
        <button className='closeButton'>
          <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L49 49" stroke="white" stroke-width="3"/>
            <path d="M49 2L2 49" stroke="white" stroke-width="3"/>
          </svg>
        </button>
        
        <div className="embedHolder">
          <div className="embedInner">
            
            <iframe width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen />

          </div>
        </div>
      
      </motion.div> }
    </AnimatePresence>
  )
}
