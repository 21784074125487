import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { productionHolder } from "./production.module.scss"
import Trailer from "../components/components/productions/Trailer"
import { LeftSpeckles, RightSpeckles } from "../components/components/Speckles"
import Seo from "../components/components/Seo"

export default function ProductionTemplate({ data }) {
  const prod = data.allWpProduction.nodes[0]
  const [showTrailer, setShowTrailer] = useState(false)

  const image = getImage(prod.featuredImage?.node.localFile)

  return (
    <article className={productionHolder}>
      <Seo title={prod.title} description="A Good Chaos production" />

      {image && (
        <GatsbyImage
          className="featuredImage"
          image={image}
          alt={prod.title}
          objectPosition={prod.featuredImageFocus.focusPoint}
        />
      )}
      {prod?.trailer_url?.trailerUrl && (
        <Trailer
          showTrailer={showTrailer}
          setShowTrailer={setShowTrailer}
          trailer={prod.trailer_url.trailerUrl}
        />
      )}
      <div className="productionInner">
        <header>
          <h1>{prod.title}</h1>
          <div className="statusHolder">
            {prod.coProductionUK.coProductionUk && (
              <div className="status">Co Production UK</div>
            )}
            {prod.release_info.releaseDateText && (
              <div className="status">{prod.release_info.releaseDateText}</div>
            )}
          </div>
        </header>

        <div className="details">
          {prod.credits_concise.credit1 && (
            <ul className="credits">
              {prod.credits_concise.credit1 && (
                <li>{prod.credits_concise.credit1}</li>
              )}
              {prod.credits_concise.credit2 && (
                <li>{prod.credits_concise.credit2}</li>
              )}
              {prod.credits_concise.credit3 && (
                <li>{prod.credits_concise.credit3}</li>
              )}
              {prod.credits_concise.credit4 && (
                <li>{prod.credits_concise.credit4}</li>
              )}

              {prod.credits_extended.credit1 && (
                <li>{prod.credits_extended.credit1}</li>
              )}
              {prod.credits_extended.credit2 && (
                <li>{prod.credits_extended.credit2}</li>
              )}
              {prod.credits_extended.credit3 && (
                <li>{prod.credits_extended.credit3}</li>
              )}
              {prod.credits_extended.credit4 && (
                <li>{prod.credits_extended.credit4}</li>
              )}
              {prod.credits_extended.credit5 && (
                <li>{prod.credits_extended.credit5}</li>
              )}
              {prod.credits_extended.credit6 && (
                <li>{prod.credits_extended.credit6}</li>
              )}
              {prod.credits_extended.credit7 && (
                <li>{prod.credits_extended.credit7}</li>
              )}
              {prod.credits_extended.credit8 && (
                <li>{prod.credits_extended.credit8}</li>
              )}
              {prod.credits_extended.credit9 && (
                <li>{prod.credits_extended.credit9}</li>
              )}
            </ul>
          )}
          {prod.trailer_url.trailerUrl && (
            <div className="trailerLink">
              <a
                href={prod.trailer_url.trailerUrl}
                title={`Watch the ${prod.title} trailer`}
                onClick={e => {
                  e.preventDefault()
                  setShowTrailer(true)
                }}
              >
                Watch Trailer
              </a>
            </div>
          )}
        </div>

        {prod.content && (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: prod.content }}
          />
        )}
      </div>

      <LeftSpeckles />
      <RightSpeckles />
    </article>
  )
}

export const query = graphql`
  query ($id: Int!) {
    allWpProduction(filter: { databaseId: { eq: $id } }) {
      nodes {
        coProductionUK {
          coProductionUk
        }
        credits_concise {
          credit1
          credit2
          credit3
          credit4
        }
        credits_extended {
          credit1
          credit2
          credit3
          credit4
          credit5
          credit6
          credit7
          credit8
          credit9
        }
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        featuredImageFocus {
          focusPoint
        }
        title
        slug
        release_info {
          releaseDate
          releaseDateText
        }
        menuOrder
        terms {
          nodes {
            taxonomyName
            name
            termTaxonomyId
            id
          }
        }
        status
        trailer_url {
          trailerUrl
        }
      }
    }
  }
`
